import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import Button from 'components/Button'

import './ErrorModal.scss'

class ErrorModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
    }
  }

  closeModal = () => {
    this.setState({open: false})
  }

  render() {
    const { open, children } = this.props

    if (open) {
      return(
        <Modal
          isOpen={this.state.open}
          ariaHideApp={false} // Bug in react-modal? Shows warnings if this is true but breaks accessability
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '25vh',
              bottom: '25vh',
              padding: '0px',
              border: '',
              boxShadow: '1px 1px rgba(0, 0, 0, 0.75)',
            }
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h2>Whoops...</h2>
            </div>
            <div className="modal-body">
              {children}
              <div style={{ width: '100%', textAlign: 'center'}}>
                <Button
                  size='large'
                  color='error'
                  onClick={this.closeModal}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )
    } else {
      return(null)
    }
  }
}

ErrorModal.propTypes = {
  open: PropTypes.bool,
}

ErrorModal.defaultProps = {
  open: false
}

export default ErrorModal
