import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import './index.scss'
import routes from './routes/root'

import * as serviceWorker from './serviceWorker'

import { apiMiddleware } from 'redux-api-middleware'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import configureStore from './configureStore'
import UserInfoContainer from 'containers/UserInfoContainer'
import BrowserCheck from 'components/BrowserCheck'

import icons from 'icons' // eslint-disable-line no-unused-vars

const history = createBrowserHistory();

const middleware = [
  apiMiddleware,
  thunk,
  routerMiddleware(history),
];

const store = configureStore(history, {}, middleware);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <UserInfoContainer>
        <BrowserCheck>
          {routes}
        </BrowserCheck>
      </UserInfoContainer>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
