import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './CategoryButton.scss'

class CategoryButton extends Component {
  handleClick = (event) => {
    event.preventDefault()
    this.props.handleClick()
  }

  render() {
    const { title, icon, selected } = this.props
    const classes = classNames(
      'category-button',
      {selected: selected},
    )

    return (
      <div className={classes} onClick={this.handleClick}>
        <FontAwesomeIcon icon={icon} size='2x' />
        <span>{title}</span>
      </div>
    );
  }
}

CategoryButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
}

CategoryButton.defaultProps = {
  selected: false,
}

export default CategoryButton;
