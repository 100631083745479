import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class MainHeader extends PureComponent {
  render() {
    return(
      <header>
        <Link to="/"><h1>Shuffled</h1></Link>
        <Link to="/settings">
          <FontAwesomeIcon
            icon={{prefix: 'far', iconName:'user-circle'}}
          />
        </Link>
      </header>
    )
  }
}

export default MainHeader;
