import React, { Component } from 'react';

class Welcome2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      next: null,
    }
  }

  componentDidMount() {
    const intervalId = setInterval(this.giveInstruction, 5000)
    this.setState({ intervalId: intervalId})
  }

  giveInstruction = () => {
    this.setState({next: 'Go ahead, do it again...'})
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  render() {
    return (
      <main className="welcome-2">
        <h2>Swiping is important</h2>
        <p>
        See that wasn't so hard. It's kind of addictive isn't it.
        </p>
        <h2>
        {this.state.next}
        </h2>
      </main>
    );
  }
}

export default Welcome2;
