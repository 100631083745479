import { createAction } from 'redux-actions'

export const WELCOME_KEY = 'welcome_completed'

export const SET_WELCOME = 'SET_WELCOME'
export const GET_WELCOME = 'GET_WELCOME'

export const getWelcome = createAction(GET_WELCOME, () => {
  let welcome = localStorage.getItem(WELCOME_KEY)

  if(!welcome) {
    welcome = false
    localStorage.setItem(WELCOME_KEY, welcome)
  }

  return {
    value: welcome
  }
})

export const setWelcome = createAction(SET_WELCOME, (value) => {
  let welcome = localStorage.getItem(WELCOME_KEY)

  if(!welcome || welcome !== value) {
    localStorage.setItem(WELCOME_KEY, value)
  }

  return {
    value: value
  }
})
