import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import createRootReducer from './reducers'

export default function configureStore(history, initialState, middleware) {
  const store = createStore(
    createRootReducer(history),
    {
      ...initialState,
    },
    composeWithDevTools(applyMiddleware(...middleware)),
  );
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(nextRootReducer(history));
    });
  }

  /*
  store.subscribe(() => {
    // Store our token in localStorage
    const newKey = store.getState().auth.key;
    if (newKey !== currentAuthToken) {
      currentAuthToken = newKey;
      setAuthKey(newKey);
    }
  });
  */
  return store;
}
