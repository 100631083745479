import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setWelcome } from 'actions/welcome'
import { toggleCategory } from 'actions/cards'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CategoryButton from 'components/CategoryButton'
import Button from 'components/Button'

import './CategorySelect.scss'

class CategorySelect extends Component {

  selectCategory = (id) => {
    this.props.toggleCategory(id)
  }

  selectAll = (event) => {
    event.preventDefault()
    Object.entries(this.props.categories).forEach( ([key, value]) => {
      if (value === false) {
        this.props.toggleCategory(key)
      }
    })
  }

  goShuffle = (event) => {
    event.preventDefault()

    // End the welcome phase
    this.props.setWelcome(true)

    // Take them to the cards
    this.props.history.push('/cards')
  }

  render() {
    const { categories } = this.props

    return (
      <React.Fragment>
      <div className="category-select-container">
        <CategoryButton
          id='funny'
          title='Funny'
          icon='carrot'
          selected={categories.funny}
          handleClick={() => this.selectCategory('funny')}
        />
        <CategoryButton
          id='inspiration'
          title='Inspiration'
          icon='lightbulb'
          selected={categories.inspiration}
          handleClick={() => this.selectCategory('inspiration')}
        />
        <CategoryButton
          id='today'
          title='Today'
          icon='calendar-alt'
          selected={categories.today}
          handleClick={() => this.selectCategory('today')}
        />
        <CategoryButton
          id='quotes'
          title='Quotes'
          icon='pen-nib'
          selected={categories.quotes}
          handleClick={() => this.selectCategory('quotes')}
        />
        <CategoryButton
          id='facts'
          title='Facts'
          icon='book-open'
          selected={categories.facts}
          handleClick={() => this.selectCategory('facts')}
        />
        <CategoryButton
          id='words'
          title='Words'
          icon='signature'
          selected={categories.words}
          handleClick={() => this.selectCategory('words')}
        />
      </div>
      <div className="actions">
        <button className="link" onClick={this.selectAll}>Select All</button>
        <Button onClick={this.goShuffle}>
          Get Shufflin'
          {' '}
          <FontAwesomeIcon icon='long-arrow-alt-right' />
        </Button>
      </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.cards.categories,
})

const mapDispatchToProps = {
  setWelcome: setWelcome,
  toggleCategory: toggleCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelect);
