import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHeart as faSolidHeart,
  faLongArrowAltRight,
  faCarrot,
  faLightbulb,
  faCalendarAlt,
  faPenNib,
  faBookOpen,
  faSignature,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faHeart, faUserCircle } from '@fortawesome/free-regular-svg-icons'

export default library.add(
  faSolidHeart,
  faHeart,
  faUserCircle,
  faLongArrowAltRight,
  faCarrot,
  faLightbulb,
  faCalendarAlt,
  faPenNib,
  faBookOpen,
  faSignature,
  faExternalLinkAlt,
)
