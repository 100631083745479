import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Button.scss'

class Button extends Component {
  render() {
    const { children, color, size, onClick } = this.props
    const classes = classNames(
      'button',
      {info: color === 'info'},
      {error: color === 'error'},
      {success: color === 'success'},
      {warning: color === 'warning'},
      {small: size === 'small'},
      {large: size === 'large'},
    )

    return(
      <button className={classes} onClick={onClick}>
      {children}
      </button>
    )
  }
}

Button.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
}

Button.defaultProps = {
  color: 'info',
  size: 'normal',
}

export default Button
