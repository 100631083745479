import React, { Component } from 'react'
import classNames from 'classnames'

class ImageCard extends Component {
  render() {
    return (
      <div className="card card-image">
        <h2>ImageCard</h2>
      </div>
    );
  }
}

export default ImageCard
