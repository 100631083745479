import React, { Component } from 'react'

import MainHeader from 'components/MainHeader'

class Card extends Component {
  render() {
    return (
      <div className="container">
        <MainHeader />
        <h2>Card</h2>
      </div>
    );
  }
}

export default Card
