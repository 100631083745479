import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class LinkCard extends Component {
  render() {
    const {
      title, short_description, url, cta, description, theme
    } = this.props

    const classes = classNames(
      'card',
      'card-link',
      {'card-theme-1': theme === 'theme-1'},
      {'card-theme-2': theme === 'theme-2'},
      {'card-theme-3': theme === 'theme-3'},
    )

    return (
      <div className={classes}>
        <h2>{title}</h2>

        <p className="short-description">
        {short_description}
        </p>

        <div className="cta">
          <a href={url} className="cta" target="_blank">
            {cta}
            {' '}
            <FontAwesomeIcon icon="external-link-alt" />
          </a>
        </div>

        <p>
        {description}
        </p>
      </div>
    );
  }
}

LinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  cta: PropTypes.string,
  theme: PropTypes.string,
}

LinkCard.defaultProps = {
  cta: 'Go to site',
  theme: 'default',
}

export default LinkCard
