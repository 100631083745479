import React, { PureComponent } from 'react';
import CategorySelect from 'components/CategorySelect'


class Welcome4 extends PureComponent {
  render() {
    return (
      <main className="welcome-4">
        <h2>Pick your categories</h2>
        <p>
        You can change them whenever. Choose them all or pick the ones you are
        in the mood for.
        </p>
        <CategorySelect {...this.props} />
      </main>
    );
  }
}


export default Welcome4
