import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUserID } from 'actions/user'
import { getWelcome } from 'actions/welcome'

class UserInfo extends Component {
  componentDidMount() {
    this.props.load()
    this.props.getWelcome()
  }
  render() {
    return(
      <div>{this.props.children}</div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  welcome: state.welcome,
})

const mapDispatchToProps = {
  load: getUserID,
  getWelcome: getWelcome,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)
