import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorModal from 'components/ErrorModal'


class BrowserCheck extends Component {
  constructor(props) {
    super(props)
    this.state = {
      online: props.online,
      storage: props.storage,
    }
  }

  checkStorage = () => {
    const test = 'shuffled-testing'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      this.setState({ storage: true})
    } catch(e) {
      this.setState({ storage: false})
    }
  }

  async checkOnline () {

    // Figure out if we're on prod or not
    let url = 'https://shuffled.io/manifest.json'
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      url = 'http://localhost:3000/manifest.json'
    }

    try {
      const response = await fetch(url)
      const data = await response.json()

      if (data && data.short_name === 'Shuffled') {
        this.setState({online: true})
      }
      else {
        this.setState({online: false})
      }
    } catch(e) {
      this.setState({online: false})
    }

  }

  componentDidMount() {
    if (this.state.online === undefined) {
      this.checkOnline()
    }
    if(this.state.storage === undefined) {
      this.checkStorage()
    }
  }

  render() {
    const { children } = this.props
    return(
      <React.Fragment>

        {this.state.online === false && (
          <ErrorModal open={true}>
            <p>
            Sorry to say it, but it appears that you are not online.  Shuffled
            probably won't work correctly for you.
            </p>
          </ErrorModal>
        )}

        {!this.state.storage && (
          <ErrorModal open={true}>
            <p>
            Something happened and we need to talk about it. It looks like
            your browser doesn't support localStorage, which is kind of like
            a browser cookie.
            </p>
            <p>
            Shuffled uses localStorage for a lot of things, like keeping
            track of who you are, your current session, which categories you've
            indicated you want to see, etc.
            </p>
          </ErrorModal>
        )}

        {children}
      </React.Fragment>
    )
  }
}

BrowserCheck.propTypes = {
  storage: PropTypes.bool,
  online: PropTypes.bool,
}

BrowserCheck.defaultProps = {
  storage: undefined,
  online: undefined,
}

export default BrowserCheck
