import { TOGGLE_CATEGORY } from 'actions/cards'

const initialState = {
  categories: {
    funny: false,
    inspiration: false,
    today: false,
    quotes: false,
    facts: false,
    words: false,
  },
}

const cards = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CATEGORY: {
      const id = action.payload.id
      const categories = {...state.categories}
      categories[id] = !categories[id]

      return {
        ...state,
        categories: categories,
      }
    }
    default:
      return state;
  }
};

export default cards;
