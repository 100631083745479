import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './InitialScreen.scss'

class InitialScreen extends Component {
  componentDidMount() {
    setTimeout(this.gotoWelcome, 2000)
  }

  gotoWelcome = () => {
    this.props.history.push('/welcome')
  }

  render() {
    return (
      <div className="InitialScreen">
        <h1>Shuffled</h1>
      </div>
    );
  }
}

InitialScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  })
};

export default InitialScreen;
