import { connect } from 'react-redux'
import Welcome from 'components/welcome/Welcome'

const mapStateToProps = state => ({
  user: state.user,
  welcome: state.welcome,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
