import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Welcome.scss';

import MainHeader from 'components/MainHeader'

import Welcome1 from './Welcome1'
import Welcome2 from './Welcome2'
import Welcome3 from './Welcome3'
import Welcome4 from './Welcome4'

class Welcome extends Component {
  constructor(props) {
    super(props)
    let index = 0

    // Set the page based on URL param
    if (props.match.params.index) {
      index = parseInt(props.match.params.index) - 1
    }
    this.state = {
      index: index
    }
  }

  updateIndex = (index, indexLatest, meta) => {
    this.setState({index: index})
    this.props.history.push(`/welcome/${index +1}`)
  }

  render() {
    const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);
    return (
      <div className="container">
        <MainHeader />
        <BindKeyboardSwipeableViews
          index={this.state.index}
          onChangeIndex={this.updateIndex}
          enableMouseEvents
          resistance
        >
          <Welcome1 />
          <Welcome2 />
          <Welcome3 />
          <Welcome4 history={this.props.history} />
        </BindKeyboardSwipeableViews>
        <footer>
          <p>
            Brought to you with
            {' '}
            <FontAwesomeIcon icon="heart" className="font-red" />
            {' '}
            from REVSYS
          </p>
        </footer>
      </div>
    );
  }
}

export default Welcome;
