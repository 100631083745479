import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Welcome3 extends Component {
  render() {
    return (
      <main className="welcome-3">
        <h2>Saving</h2>
        <p>
        Eventually you will be able to swipe down to save a card into your
        personal stack. This is also akin to <i>liking</i> something.
        </p>
        <p>
        To save a card just tap the
        {' '}
        <FontAwesomeIcon
          icon='heart'
          size='2x'
        />
        {' '}
        and we'll save it for you.
        </p>
        <p>
        Next you just need to pick out which categories of cards you want to
        view today.
        </p>
        <p>
        Swipe
        {' '}
        <FontAwesomeIcon icon='long-arrow-alt-right' />
        {' '}
        one more time to pick your categories.
        </p>
      </main>
    );
  }
}

export default Welcome3;
