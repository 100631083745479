import React, { Component } from 'react';
import Button from 'components/Button'

import MainHeader from 'components/MainHeader'

class Settings extends Component {
  handleResetWelcome = (e) => {
    e.preventDefault()
    this.props.setWelcome(false)
  }

  render() {
    return(
      <div className="container">
        <MainHeader />
        <main>
          <h1>Show user settings here...</h1>
          <Button color='error' onClick={this.handleResetWelcome}>
          Reset Welcome
          </Button>
        </main>
      </div>
    )
  }
}

export default Settings;
