import React from 'react';
import { Route, Switch } from 'react-router-dom'

import InitialScreen from 'components/welcome/InitialScreen'
import WelcomeContainer from 'containers/WelcomeContainer'
import SettingsContainer from 'containers/SettingsContainer'
import CardDetailContainer from 'containers/CardDetailContainer'
import CardListContainer from 'containers/CardListContainer'

const routes = (
  <Switch>
    <Route exact path="/" component={InitialScreen} />
    <Route exact path="/settings" component={SettingsContainer} />
    <Route exact path="/welcome/:index" component={WelcomeContainer} />
    <Route exact path="/welcome" component={WelcomeContainer} />
    <Route path="/cards" component={CardListContainer} />
    <Route path="/card" component={CardDetailContainer} />
  </Switch>
);

export default routes;
