import React, { Component } from 'react';

class Welcome2 extends Component {
  render() {
    return (
      <main className="welcome-1">
        <h2>Welcome to Shuffled</h2>
        <p>
        We see that you're new to Shuffled, so let us take a moment to
        explain to you how this all works.  Get ready for the ultimate in
        mobile time wasting...
        </p>

        <p>
        Shuffled shows you individual cards of information from categories
        you select.  If you find the card interesting, you can often scroll down
        to read more on that topic.  If you don't like just swipe left and a
        new card is brought up.
        </p>

        <p>
        Give it a try, using your thumb swipe to the left now...
        </p>
      </main>
    );
  }
}

export default Welcome2;
