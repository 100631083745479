import { createAction } from 'redux-actions'

import { getOrCreateUser } from '../auth'

export const GET_USER_ID = 'GET_USER_ID'
export const getUserID = createAction(GET_USER_ID, () => {
  const {user_id, created} = getOrCreateUser()

  if(created) {
    console.log(`=== Created new user UUID='${user_id}'`)
  }

  return {
    id: user_id,
    created: created,
  }
})
