import React, { Component } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'

import MainHeader from 'components/MainHeader'
import LinkCard from 'components/cards/LinkCard'
import ImageCard from 'components/cards/ImageCard'
import QuoteCard from 'components/cards/QuoteCard'

import './CardList.scss'
import './cards/Cards.scss'

const VirtaulizeSwapableViews = virtualize(SwipeableViews)

class CardList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cards: this.getCards(100),
    }
  }

  renderSlides = ({key, index}) => {
    console.log(`Key: ${key} Index: ${index}`)
    return this.state.cards[index]
  }

  getCards = (count) => {
    let cards = []
    console.log(`Cound: ${count}`)
    for (var i = 0; i < count; i++) {
      let choice = Math.floor(Math.random() * 7)

      switch (choice) {
        case 0:
          cards.push(
            <LinkCard
              key={i}
              title="Keeping Django Models Ordered"
              short_description="How to smartly keep a set of Django ORM objects in order for drag-n-drop like situations"
              description="Most of us know how to order objects using the Django ORM using order_by('field') which creates a SQL query for us using ORDER BY field. That's not the type of ordering I'm going to talk about today."
              cta="Read full article"
              url="https://www.revsys.com/tidbits/keeping-django-model-objects-ordered/"
            />
          )
          break
        case 1:
          cards.push(
            <LinkCard
              key={i}
              title="Tips for Using Django's ManyToManyField"
              short_description="ManyToManyFields are confusing m'kay"
              description="ManyToManyFields confuse a lot of people. The way you relate objects to each other is just different enough from dealing with ForeignKeys and just uncommon enough in day-to-day Django development that it's easy to forget all the little tricks for dealing with them."
              url="https://www.revsys.com/tidbits/tips-using-djangos-manytomanyfield/"
              theme="theme-1"
            />
          )
          break
        case 2:
          cards.push(
            <LinkCard
              key={i}
              title="Pro-Tip - Slugs as primary keys"
              short_description="A very common pattern in a Django Project is to have some kind of 'model-type' relationship, where you have some kind of object that can only belong to one of the types defined in the database."
              description="If somehow you already have the pk of the EventType (for example, it might come from an API payload or from the URL), then you can easily avoid the lookup by setting the primary key directly into the type_id column:"
              url="https://www.revsys.com/tidbits/slugs-primary-keys/"
              theme="theme-2"
            />
          )
          break
        case 3:
          cards.push(<ImageCard key={i} />)
          break
        case 4:
          cards.push(
            <QuoteCard
              key={i}
              quote="Great minds have purposes; others have wishes"
              attribution="Washington Irving"
            />
          )
          break
        case 5:
          cards.push(
            <QuoteCard
              key={i}
              quote="Christmas isn't a season. It's a feeling."
              attribution="Edna Ferber"
              theme="theme-1"
            />
          )
          break
        case 6:
          cards.push(
            <QuoteCard
              key={i}
              quote="Happiness is having a large, loving, caring, close-knit family, in another city."
              attribution="George Burns"
              theme="theme-2"
            />
          )
          break
        default:
          cards.push(<LinkCard key={i} />)
      }
    }

    return cards
  }

  render() {
    return (
      <div className="card-container">
        <MainHeader />
        <div className="card-list">
          <VirtaulizeSwapableViews
            enableMouseEvents
            resistance={true}
            overscanSlideBefore={2}
            overscanSlideAfter={3}
            slideRenderer={this.renderSlides}
          />
        </div>
      </div>
    );
  }
}

export default CardList
