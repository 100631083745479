import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from './user'
import cards from './cards'

export default (history) => combineReducers({
  cards: cards,
  user: user,
  router: connectRouter(history),
});
