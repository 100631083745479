import { connect } from 'react-redux'
import { setWelcome } from 'actions/welcome'

import Settings from 'components/Settings'

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = {
  setWelcome: setWelcome,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
