import { GET_USER_ID } from 'actions/user'
import { SET_WELCOME, GET_WELCOME } from 'actions/welcome'

const initialState = {
  id: undefined,
  created: undefined,
  welcome_completed: undefined,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ID: {
      return {
        ...state,
        id: action.payload.id,
        created: action.payload.created,
      }
    }
    case SET_WELCOME: {
      return {
        ...state,
        welcome_completed: action.payload.value,
      }
    }
    case GET_WELCOME: {
      return {
        ...state,
        welcome_completed: action.payload.value,
      }
    }
    default:
      return state;
  }
};

export default user;
