import uuid4 from 'uuid/v4'

export const USER_ID_KEY = 'user_id'

// Generate a new ID for this user since we did not find one
export const createUserID = () => {
  return uuid4()
}

// Generate or retrieve this user's ID from local storage
export const getOrCreateUser = () => {
  // see if we have an existing ID in local storage
  let created = false
  let user_id = localStorage.getItem(USER_ID_KEY)

  // Not finding one, we need to generate and store it
  if (!user_id) {
    user_id = createUserID()
    created = true
    localStorage.setItem(USER_ID_KEY, user_id)
  }

  return {
    user_id: user_id,
    created: created,
  }

}
