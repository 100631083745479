import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class QuoteCard extends Component {
  render() {
    const {
      quote, attribution, theme
    } = this.props

    const classes = classNames(
      'card',
      'card-quote',
      {'card-theme-1': theme === 'theme-1'},
      {'card-theme-2': theme === 'theme-2'},
      {'card-theme-3': theme === 'theme-3'},
    )
    return (
      <div className={classes}>
        <h2>Quote</h2>

        <div className="quote">
          <div className="quote-body">{quote}</div>
          <div className="quote-attribution">&mdash; {attribution}</div>
        </div>

      </div>
    );
  }
}

QuoteCard.propTypes = {
  quote: PropTypes.string.isRequired,
  attribution: PropTypes.string.isRequired,
  theme: PropTypes.string,
}

QuoteCard.defaultProps = {
  theme: 'default',
}

export default QuoteCard
